import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from "react";
import { CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";
import { AuthView } from "./views/AuthView";
import { useLocation, useNavigate } from "react-router-dom";
import { LoadingView } from "../views/LoadingView/LoadingView";
import { getUnixTime } from "date-fns";
import useLocalStorage from "../hooks/useLocalStorage";
import { signOut } from "./cognito/SignOut";
import { getSession, refreshSession } from "./cognito/Session";

export interface Auth {
    token: string;
    logout: () => void;
    mfaEnabled: boolean;
    setMfaEnabled: (enabled: boolean) => void;
    refreshUserSession: (forceRefresh?: boolean) => Promise<void>;
    username: string;
    user: CognitoUser;
}

const AuthContext = createContext<null | Auth>(null);

export const useAuth = (): Auth => {
    const auth = useContext(AuthContext);
    if (auth === null) throw new Error("Auth context not set");
    return auth;
};

export const AuthContextProvider = ({ children }: PropsWithChildren<unknown>) => {
    const location = useLocation();
    const navigate = useNavigate();

    const queryParams = new URLSearchParams(location.search);

    const [initialLocation] = useState(location);

    const [initialized, setInitialized] = useState(false);
    const [session, setSession] = useState<CognitoUserSession | null>(null);
    const [user, setUser] = useState<CognitoUser | null>(null);

    // Cognito does not reflect changes in the MFA settings until the user logs out and back in once.
    // Refreshing the session and even reloading the page does not seem to have any effect on the settings.
    // As a workaround, we keep track of this state in locale storage.
    // TODO find out why cognito does not update this info
    const [mfaEnabled, setMfaEnabled] = useLocalStorage<boolean | null>("mfaEnabled", null);

    const logout = useCallback(() => {
        setMfaEnabled(null);
        signOut();
        window.location.reload();
    }, []);

    const handleSessionInit = useCallback(async (forceRefresh = false) => {
        try {
            const result = await getSession(queryParams.has("forceRefreshToken") || forceRefresh);
            if (result === null) {
                setInitialized(true);
                return;
            }
            const { session, user } = result;
            setSession(session);
            setUser(user);
            user.getUserData((err, user) => {
                setMfaEnabled((currentState) => {
                    if (currentState === null) {
                        return user?.UserMFASettingList?.includes("SOFTWARE_TOKEN_MFA") ?? false;
                    }
                    return currentState;
                });
            });
            navigate(initialLocation, { replace: true });
        } catch (err: unknown) {
            if (
                typeof err === "object" &&
                err !== null &&
                "code" in err &&
                err.code === "PasswordResetRequiredException"
            ) {
                navigate("/change-password", { replace: true });
            }
        }
        setInitialized(true);
    }, []);

    const handleSessionRefresh = useCallback(async () => {
        if (session === null) {
            return;
        }

        try {
            const refreshedSession = await refreshSession(session.getRefreshToken());
            setSession(refreshedSession);
        } catch (err) {
            setSession(null);
        }
    }, [session]);

    // Initially refresh the session to get a valid token
    useEffect(() => {
        handleSessionInit();
    }, [handleSessionInit]);

    const tokenExpirationTimestamp = session?.getIdToken().getExpiration();

    // Refresh the session before it expires, to always have a valid token
    useEffect(() => {
        if (tokenExpirationTimestamp) {
            const expiresInSeconds = tokenExpirationTimestamp - getUnixTime(new Date());
            // refresh 10 minutes before expiration
            const refreshInMs = (expiresInSeconds - 10 * 60) * 1000;
            const timeout = setTimeout(handleSessionRefresh, refreshInMs);
            return () => clearTimeout(timeout);
        }
    }, [tokenExpirationTimestamp, handleSessionRefresh]);

    if (!initialized) return <LoadingView />;

    if (!session || !user) {
        return <AuthView refreshSession={handleSessionInit} />;
    }

    const auth: Auth = {
        token: session.getIdToken().getJwtToken(),
        logout,
        mfaEnabled: mfaEnabled ?? false,
        setMfaEnabled,
        refreshUserSession: handleSessionInit,
        username: user.getUsername(),
        user,
    };

    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};
