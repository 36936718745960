import { useMutation } from "react-query";
import { AuthenticationDetails, CognitoUserSession, IAuthenticationDetailsData } from "amazon-cognito-identity-js";
import { getCognitoUserFromAuthDetails } from "./helpers";
import { AuthenticateError } from "./AuthenticateError";

export const useAuthenticate = () => {
    return useMutation<CognitoUserSession, AuthenticateError, IAuthenticationDetailsData>(
        "authenticate",
        (authData) => {
            return authenticate(authData);
        }
    );
};
const authenticate = async (authenticationData: IAuthenticationDetailsData): Promise<CognitoUserSession> =>
    new Promise<CognitoUserSession>((resolve, reject) => {
        const authenticationDetails = new AuthenticationDetails(authenticationData);
        const cognitoUser = getCognitoUserFromAuthDetails(authenticationData);

        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: (session) => {
                resolve(session);
            },
            onFailure: (err: unknown) => {
                if (
                    typeof err === "object" &&
                    err !== null &&
                    "code" in err &&
                    err.code === "PasswordResetRequiredException"
                ) {
                    reject({
                        type: "passwordResetRequired",
                    });
                    return;
                }

                reject({
                    type: "unknown",
                    // @ts-ignore
                    message: err?.message ?? JSON.stringify(err),
                });
            },
            newPasswordRequired(userAttributes) {
                reject({
                    type: "newPasswordRequired",
                    userAttributes,
                    cognitoUser,
                });
            },

            // 2FA
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            mfaSetup: (challengeName, challengeParameters) => {
                // TODO change reject type to totpRequired and provide secret, to display the mfa setup dialog
                //  to do this we need to find out what the challengeParameters contain, by forcing a test user to
                //  setup 2fa and check. (It's not typed nor documented)
                reject({
                    type: "unknown",
                    message: "MFA setup required",
                });
            },

            totpRequired: () => {
                reject({
                    type: "totpRequired",
                    cognitoUser,
                });
            },
        });
    });
