import React, { ButtonHTMLAttributes, forwardRef, PropsWithChildren } from "react";
import styles from "./Button.module.css";
import { c } from "../../../utils/ClassesHelper";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    wide?: boolean;

    icon?: "list" | "grid" | "more" | "close" | "delete" | "right" | "left" | "swap";
    borderless?: boolean;
    active?: boolean;

    variant?: "outline" | "filled";
    color?: "primary" | "error";
    /** @deprecated Use color and variant instead */
    primary?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, PropsWithChildren<ButtonProps>>(function Button(
    { wide, primary, icon, borderless, active, variant, color, className, children, ...props }: ButtonProps,
    ref
) {
    if (primary === true) {
        variant = "filled";
        color = "primary";
    }

    const classNames = c(
        [styles.button, icon, styles[variant ?? "outline"], styles[color ?? "primary"]],
        {
            [styles.wide]: wide ?? false,
            [styles.active]: active === true,
            [styles.inactive]: active === false,
            [styles.borderless]: borderless ?? false,
            [styles.disabled]: props.disabled ?? false,
            [styles.textButton]: children !== undefined,
            [styles.iconButton]: icon !== undefined,
        },
        [className]
    );

    return (
        <button className={classNames} {...props} ref={ref} type={props.type ?? "button"}>
            {children}
        </button>
    );
});
