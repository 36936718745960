import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { TabProps } from "../../TabProps";
import { GetUpSettings } from "./GetUpSettings";
import { AbsenceSettings } from "./AbscenceSettings";
import { Label } from "../../../../UI/Label";
import _ from "lodash";
import { InputGroup } from "../../../../UI/InputGroup";
import { SceneLabel } from "../../../../../api_v2/hooks/useSceneLabels";
import { ButtonGroup } from "../../../../UI/Button/ButtonGroup";
import { Button } from "../../../../UI/Button/Button";
import { NoSettingsErrorMessage } from "../../Common/NoSettingsErrorMessage";
import { GlobalBedLocationSettings } from "./GlobalBedLocationSettings";
import { config } from "../../../../../config";
import { Settings } from "../../../../../api_v2/types/custom";
import { BedRestlessnessSettings } from "./BedRestlessnessSettings";
import { useHasFeatureFlags } from "../../../../../hooks/useHasFeatureFlags";
import { c } from "../../../../../utils/ClassesHelper";
import {
    AbsenceDetectionLocation,
    addDefaultSettingsForNewSceneLabel,
    BedRestlessnessLocation,
    getAbsenceDetectionLocationsDefault,
    getBedRestlessnessLocationDefault,
    getGetUpDetectionLocationsDefault,
    GetUpDetectionLocation,
} from "../../Common/SceneLabelDefaultSettings";
import styles from "./BedLocationSettings.module.css";

export interface BedLocationsSettingsProps extends TabProps {
    selectedBedLocation: SceneLabel;
}

export const BedLocationsSettings = (props: BedLocationsSettingsProps) => {
    const { t } = useTranslation();

    // @ts-expect-error selectedBedLocation can be of type line-2d while the filtered array can only contain bed-points. TS is too smart here, since we do check if the element has been found later anyway
    const index = props.sceneLabels.filter((l) => l.type === "bed-point").indexOf(props.selectedBedLocation);
    const checkFeatureFlag = useHasFeatureFlags();

    const bedRestlessnessEnabled = checkFeatureFlag("enable-bed-restlessness");

    // We want to hold a temporary copy of the settings in these refs, because whenever the uses switches between absence and getup settings,
    // the other one would be deleted
    const tmpAbsenceDetectionSettings = useRef<AbsenceDetectionLocation>();
    const tmpGetUpDetectionSettings = useRef<GetUpDetectionLocation>();
    const tmpBedRestlessnessSettings = useRef<BedRestlessnessLocation>();

    const absenceDetectionLocation = props.settings.absenceDetectionLocations?.find(
        (l) => l.id === props.selectedBedLocation.id
    );
    const getUpDetectionLocation = props.settings.getupDetectionLocations?.find(
        (l) => l.id === props.selectedBedLocation.id
    );
    const bedRestlessnessLocation = props.settings.bedRestlessnessLocations?.find(
        (l) => l.id === props.selectedBedLocation.id
    );

    const selectedAlertType = absenceDetectionLocation
        ? "absence"
        : getUpDetectionLocation
        ? "getup"
        : bedRestlessnessLocation
        ? "bedRestlessness"
        : null;

    const [selectedTab, setSelectedTab] = useState<"absence" | "getup" | "bedRestlessness" | null>(selectedAlertType);

    /**
     * Depending on the type, either sets or removes the bed location in absenceDetectionLocations or getupDetectionLocations respectively.
     * In summary, this function makes sure, that a bed location is only used in either one of these arrays, but not both.
     *
     * It also keeps a copy of the latest settings in a temporary ref, so that the settings don't reset back to the default,
     * every time the user switches between the two modes. They are temporary only though, as soon as the component gets unloaded,
     * the settings will reset back to the default settings once the user switches to the other mode
     */
    const handleAlertTypeSelect = (type: "absence" | "getup" | "bedRestlessness") => {
        setSelectedTab(type);
        const newSettings = _.cloneDeep(props.settings);

        // Keep a copy of the latest settings
        const currentAbsenceDetectionSettings = newSettings.absenceDetectionLocations?.find(
            (l) => l.id === props.selectedBedLocation.id
        );
        if (currentAbsenceDetectionSettings) {
            tmpAbsenceDetectionSettings.current = currentAbsenceDetectionSettings;
        }

        // Make sure absence detection locations is an array and does not contain the bed location if the user
        // switched to the other mode
        newSettings.absenceDetectionLocations = [
            ...(newSettings.absenceDetectionLocations?.filter(
                (l) => type !== "getup" || l.id !== props.selectedBedLocation.id
            ) ?? []),
        ];

        // If the bed location is not already in the array, add either the temporarily stored settings or the default ones
        if (
            type === "absence" &&
            newSettings.absenceDetectionLocations.findIndex((l) => l.id === props.selectedBedLocation.id) === -1
        ) {
            const settings =
                tmpAbsenceDetectionSettings.current &&
                tmpAbsenceDetectionSettings.current?.id === props.selectedBedLocation.id
                    ? tmpAbsenceDetectionSettings.current
                    : getAbsenceDetectionLocationsDefault(props.selectedBedLocation.id, props.settingsSchema);
            newSettings.absenceDetectionLocations.push(settings);
        }

        // Repeat for get up detection settings
        // Keep a copy of the latest settings
        const currentGetUpDetectionSettings = newSettings.getupDetectionLocations?.find(
            (l) => l.id === props.selectedBedLocation.id
        );
        if (currentGetUpDetectionSettings) {
            tmpGetUpDetectionSettings.current = currentGetUpDetectionSettings;
        }

        // Make sure get up detection locations is an array and does not contain the bed location if the user
        // switched to the other mode
        newSettings.getupDetectionLocations = [
            ...(newSettings.getupDetectionLocations?.filter(
                (l) => type !== "absence" || l.id !== props.selectedBedLocation.id
            ) ?? []),
        ];

        // If the bed location is not already in the array, add either the temporarily stored settings or the default ones
        if (
            type === "getup" &&
            newSettings.getupDetectionLocations.findIndex((l) => l.id === props.selectedBedLocation.id) === -1
        ) {
            const settings =
                tmpGetUpDetectionSettings.current &&
                tmpGetUpDetectionSettings.current?.id === props.selectedBedLocation.id
                    ? tmpGetUpDetectionSettings.current
                    : getGetUpDetectionLocationsDefault(props.selectedBedLocation.id, props.settingsSchema);
            newSettings.getupDetectionLocations.push(settings);
        }

        // Repeat for bedRestlessness settings
        // Keep a copy of the latest settings

        if (props.settings.bedRestlessnessEnable) {
            // Only if the restlessness package on the sensor is installed (this is not the featureflag)
            const currentBedRestlessnessDetectionSettings = newSettings.bedRestlessnessLocations?.find(
                (l) => l.id === props.selectedBedLocation.id
            );
            if (currentBedRestlessnessDetectionSettings) {
                tmpBedRestlessnessSettings.current = currentBedRestlessnessDetectionSettings;
            }

            // Make sure bedRestlessness locations is an array and does not contain the bed location if the user
            // switched to the other mode

            newSettings.bedRestlessnessLocations = [...(newSettings.bedRestlessnessLocations ?? [])];

            // If the bed location is not already in the array, add either the temporarily stored settings or the default ones
            if (
                type === "bedRestlessness" &&
                newSettings.bedRestlessnessLocations.findIndex((l) => l.id === props.selectedBedLocation.id) === -1
            ) {
                const settings =
                    tmpBedRestlessnessSettings.current &&
                    tmpBedRestlessnessSettings.current?.id === props.selectedBedLocation.id
                        ? tmpBedRestlessnessSettings.current
                        : getBedRestlessnessLocationDefault(props.selectedBedLocation.id, props.settingsSchema);
                newSettings.bedRestlessnessLocations.push(settings);
            }
        }

        props.onSettingsChange(newSettings);
    };

    const handleRemoveBedLocationSettings = () => {
        const newSettings = _.cloneDeep(props.settings);

        if (selectedTab === "absence" || selectedTab === "getup") {
            newSettings.absenceDetectionLocations = newSettings.absenceDetectionLocations?.filter(
                (l) => l.id !== props.selectedBedLocation.id
            );
            newSettings.getupDetectionLocations = newSettings.getupDetectionLocations?.filter(
                (l) => l.id !== props.selectedBedLocation.id
            );
        } else if (selectedTab === "bedRestlessness") {
            newSettings.bedRestlessnessLocations = newSettings.bedRestlessnessLocations?.filter(
                (l) => l.id !== props.selectedBedLocation.id
            );
        }

        props.onSettingsChange(newSettings);
        setSelectedTab(selectedAlertType);
    };

    // when "activate settings" is clicked, the default alert type "getup" is selected,
    // this is why useEffect is needed here, otherwise it will not show the tab of the default alert type
    // and then falsely show the the "activate settings" button again

    useEffect(() => {
        setSelectedTab(selectedAlertType);
    }, [selectedAlertType]);

    return (
        <>
            {!config.hideGlobalBedPointSettings ? <GlobalBedLocationSettings {...props} /> : null}

            <strong className={styles.title}>
                {t("labelBedMonitoringBedNumber", "Bed {{index}}", { index: index + 1 })}
            </strong>

            {selectedAlertType !== null || config.showRemoveBedLocationSettingsAsThirdTab ? (
                <>
                    <InputGroup>
                        <Label text={t("labelAlertType", "Alarm type")} />

                        {props.readonly ? (
                            // t("single", "Single room")
                            // t("shared", "Shared room")
                            <div>{t(props.settings.roomType ?? "-")}</div>
                        ) : (
                            <div className={styles.buttonContainer}>
                                <ButtonGroup>
                                    <Button
                                        active={selectedTab === "getup"}
                                        onClick={() => handleAlertTypeSelect("getup")}
                                        disabled={props.readonly}
                                        id="SelectAlertTypeButton_GetUp"
                                        className={c(styles.alarmTypePoint, {
                                            [styles.item]: true,
                                            [styles.active]: getUpDetectionLocation !== undefined,
                                            [styles.wide]: true,
                                        })}
                                    >
                                        {t("alertTypeFallPrevention", "Fall prevention")}
                                    </Button>
                                    <Button
                                        active={selectedTab === "absence"}
                                        onClick={() => handleAlertTypeSelect("absence")}
                                        disabled={props.readonly}
                                        id="SelectAlertTypeButton_Absence"
                                        className={c(styles.alarmTypePoint, {
                                            [styles.item]: true,
                                            [styles.active]: absenceDetectionLocation !== undefined,
                                            [styles.wide]: true,
                                        })}
                                    >
                                        {t("alertTypeAbsence", "Absence")}
                                    </Button>

                                    {config.showRemoveBedLocationSettingsAsThirdTab ? (
                                        <Button
                                            active={selectedAlertType === null}
                                            onClick={handleRemoveBedLocationSettings}
                                            disabled={props.readonly}
                                            id="SelectAlertTypeButton_None"
                                        >
                                            {t("alertTypeNone", "None")}
                                        </Button>
                                    ) : null}
                                </ButtonGroup>
                                {props.settings.bedRestlessnessEnable && bedRestlessnessEnabled ? (
                                    <Button
                                        active={selectedTab === "bedRestlessness"}
                                        onClick={() => handleAlertTypeSelect("bedRestlessness")}
                                        disabled={props.readonly}
                                        id="SelectAlertTypeButton_Restlessness"
                                        className={c(styles.alarmTypePoint, {
                                            [styles.item]: true,
                                            [styles.active]: bedRestlessnessLocation !== undefined,
                                            [styles.wide]: true,
                                        })}
                                    >
                                        {t("alertTypeBedRestlessness", "Restlessness")}
                                    </Button>
                                ) : null}
                            </div>
                        )}
                    </InputGroup>

                    <div>
                        {selectedTab === "absence" ? <AbsenceSettings {...props} /> : null}
                        {selectedTab === "getup" ? <GetUpSettings {...props} /> : null}
                        {selectedTab === "bedRestlessness" ? <BedRestlessnessSettings {...props} /> : null}

                        {!config.showRemoveBedLocationSettingsAsThirdTab ? (
                            <div className={styles.removeButtonContainer}>
                                {(selectedTab === "absence" && absenceDetectionLocation !== undefined) ||
                                (selectedTab === "getup" && getUpDetectionLocation !== undefined) ? (
                                    <Button onClick={handleRemoveBedLocationSettings}>
                                        {selectedTab === "absence"
                                            ? t("labelRemoveAbsenceSettings", "Remove absence settings")
                                            : selectedTab === "getup"
                                            ? t("labelRemoveGetupSettings", "Remove fall prevention settings")
                                            : null}
                                    </Button>
                                ) : null}
                                {selectedTab === "bedRestlessness" && bedRestlessnessLocation !== undefined ? (
                                    <Button onClick={handleRemoveBedLocationSettings}>
                                        {t("labelRemoveRestlessnessSettings", "Remove restlessness settings")}
                                    </Button>
                                ) : null}
                            </div>
                        ) : null}
                    </div>
                </>
            ) : null}

            {selectedAlertType === null ? (
                <NoSettingsErrorMessage
                    settings={props.settings}
                    onSettingsChange={props.onSettingsChange}
                    addSettingsHandler={(settings: Settings) =>
                        addDefaultSettingsForNewSceneLabel(
                            settings,
                            props.selectedBedLocation,
                            "bed-monitoring",
                            props.settingsSchema
                        )
                    }
                    readonly={props.readonly || config.showRemoveBedLocationSettingsAsThirdTab}
                />
            ) : null}
        </>
    );
};
